var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quiz "},[_c('div',{staticClass:"mb-5"},[_c('span',{staticClass:"d-block small font-weight-bold text-cap mb-2"},[_vm._v("Our A320 Monthly Quiz")]),_c('h2',[_vm._v(_vm._s(_vm.title))]),_vm._m(0)]),_c('div',{attrs:{"id":"quiz-content"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 col-md-8 col-sm-6 mb-3"},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.sitting.questions),function(question,questionIndex){return _c('a',{key:questionIndex,class:_vm.getNavQuestionStyle(
                                _vm.sitting.questions[questionIndex].isCorrect
                            ),attrs:{"href":("#" + questionIndex)}},[_vm._v(_vm._s(questionIndex + 1))])}),0)]),_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-6 text-right mb-3"},[(_vm.isDone)?_c('a',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"tooltip","data-placement":"top","title":"Answer all the questions to finish the test","disabled":!_vm.isDone,"href":"#results"}},[_vm._v("See results")]):_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-toggle":"tooltip","data-placement":"top","title":"Answer all the questions to finish the test","disabled":""}},[_vm._v(" See results ")])])]),_c('div',{staticClass:"text-left",attrs:{"id":"questions"}},_vm._l((_vm.sitting.questions),function(question,questionIndex){return _c('div',{key:questionIndex,staticClass:"row",attrs:{"id":questionIndex}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card mb-6 card-bordered"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"lead",domProps:{"innerHTML":_vm._s(question.content)}},[_vm._v(" "+_vm._s(question.content)+" ")]),(question.allow_multiple_answers)?_c('p',{staticClass:"text-muted"},[_vm._v(" Select all that apply ")]):_vm._e(),_c('form',{attrs:{"action":"","id":questionIndex}},[_c('div',{staticClass:"card-text"},[_c('ul',{staticClass:"list-group mb-4"},_vm._l((question.answers),function(answer,answerIndex){return _c('li',{key:answerIndex,staticClass:"list-group-item",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.selectAnswer(
                                                    questionIndex,
                                                    answerIndex
                                                )}}},[(
                                                    !question.allow_multiple_answers
                                                )?_c('label',{attrs:{"for":("Q" + questionIndex + "A" + answerIndex)}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.sitting.questions[
                                                            questionIndex
                                                        ].selectedAnswer
                                                    ),expression:"\n                                                        sitting.questions[\n                                                            questionIndex\n                                                        ].selectedAnswer\n                                                    "}],attrs:{"type":"radio","name":"answer","id":("Q" + questionIndex + "A" + answerIndex),"disabled":question.checked},domProps:{"value":answerIndex,"checked":_vm._q(
                                                        _vm.sitting.questions[
                                                            questionIndex
                                                        ].selectedAnswer
                                                    ,answerIndex)},on:{"change":function($event){return _vm.$set(_vm.sitting.questions[
                                                            questionIndex
                                                        ], "selectedAnswer", answerIndex)}}}),_vm._v(" "+_vm._s(answer.content))]):_c('label',{staticClass:"ml-2",attrs:{"for":("Q" + questionIndex + "A" + answerIndex)}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.sitting.questions[
                                                            questionIndex
                                                        ].selectedAnswer
                                                    ),expression:"\n                                                        sitting.questions[\n                                                            questionIndex\n                                                        ].selectedAnswer\n                                                    "}],attrs:{"type":"checkbox","id":("Q" + questionIndex + "A" + answerIndex),"disabled":question.checked},domProps:{"value":answerIndex,"checked":Array.isArray(
                                                        _vm.sitting.questions[
                                                            questionIndex
                                                        ].selectedAnswer
                                                    )?_vm._i(
                                                        _vm.sitting.questions[
                                                            questionIndex
                                                        ].selectedAnswer
                                                    ,answerIndex)>-1:(
                                                        _vm.sitting.questions[
                                                            questionIndex
                                                        ].selectedAnswer
                                                    )},on:{"change":function($event){var $$a=
                                                        _vm.sitting.questions[
                                                            questionIndex
                                                        ].selectedAnswer
                                                    ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=answerIndex,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sitting.questions[
                                                            questionIndex
                                                        ], "selectedAnswer", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sitting.questions[
                                                            questionIndex
                                                        ], "selectedAnswer", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sitting.questions[
                                                            questionIndex
                                                        ], "selectedAnswer", $$c)}}}}),_vm._v(" "+_vm._s(answer.content)+" ")]),(
                                                    answer.correct &&
                                                        question.checked &&
                                                        !question.isCorrect
                                                )?_c('span',{staticClass:"text-danger ml-5"},[_c('strong',[_vm._v("This was the correct answer")])]):_vm._e()])}),0),(!question.checked)?_c('b-button',{staticClass:"btn-check btn-large btn-block ",attrs:{"variant":"outline-info","disabled":question.selectedAnswer === null},on:{"click":function($event){return _vm.checkAnswer(questionIndex)}}},[_vm._v("Check")]):_c('div',[(question.isCorrect)?_c('div',{staticClass:"alert alert-soft-success",attrs:{"role":"alert"}},[_vm._v(" This is the right answer ! ")]):_c('div',{staticClass:"alert alert-soft-danger",attrs:{"role":"alert"}},[_vm._v(" Wrong answer ! ")])])],1)]),(question.checked)?_c('div',{staticClass:"card-text"},[_c('h5',[_vm._v("Explanation")]),_c('div',{domProps:{"innerHTML":_vm._s(question.explanation)}},[_vm._v(" "+_vm._s(question.explanation)+" ")])]):_vm._e()])])])])}),0)]),(_vm.showResults)?_c('div',{staticClass:"row text-left",attrs:{"id":"results"}},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card mb-4 card-bordered"},[_c('div',{staticClass:"card-body text-center "},[_vm._m(1),_c('div',{staticClass:"font-size-5 mb-5"},[_c('span',{class:_vm.scoreClass},[_vm._v(_vm._s(_vm.percentScore)+" %")])]),_c('div',{staticClass:"w-md-90 w-lg-70 mx-md-auto mb-6"},[_vm._m(2),_c('subscribe-form',{attrs:{"formId":"subscribe2"}}),_c('p',{staticClass:"small"},[_vm._v(" You can unsubscribe at any time using the link in our newsletter ")])],1),_c('b-button',{staticClass:"btn-large btn-block ",attrs:{"id":"gobacktoquiz","href":"#quiz-content","variant":"outline-info"}},[_vm._v("Go back to the quiz")])],1)])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Practice with an expertise you can thrust "),_c('i',{staticClass:"em em-wink mx-2",attrs:{"aria-role":"presentation","aria-label":"BIRD"}}),_vm._v(" "),_c('i',{staticClass:"em em-airplane",attrs:{"aria-role":"presentation","aria-label":"BIRD"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5 mb-3"},[_c('h2',[_vm._v("Your score")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[_c('h3',[_vm._v(" Register and receive new A320 quizzes every month ")])])}]

export { render, staticRenderFns }