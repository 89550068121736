<template>
    <div class="w-md-80 w-lg-50 text-center mx-md-auto">
        <div class="mb-5">
            <span class="d-block small font-weight-bold text-cap mb-2">Subscribe</span>
            <h2>Receive our A320 newsletter with new quizzes every month</h2>
        </div>
        <subscribe-form></subscribe-form>

        <p class="small">You can unsubscribe at any time using the link in our newsletter</p>
    </div>
</template>

<script>

import SubscribeForm from './SubscribeForm.vue';
export default {
    name: 'Subscribe',
    components: { 'subscribe-form': SubscribeForm }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
