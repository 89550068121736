<template>
    <div class="d-flex flex-wrap align-items-center justify-content-between">

        <ShareNetwork
            network="facebook"
            url="https://a320expert.com"
            title="Pilot friends, can you do better than me at the A320 Expert quiz ?"
            description="Free monthly quiz, made by pilots for pilots"
            quote="Pilot friends, can you do better than me at the A320 Expert quiz ?"
            hashtags="A320,quiz,pilot,airline"
            class="btn my-1"
            role="button"
        >
            <i class="fab fa-facebook"></i>
            Facebook
        </ShareNetwork>

        <ShareNetwork
            network="whatsapp"
            url="https://a320expert.com"
            title="Pilot friends, can you do better than me at the A320 Expert quiz ?"
            description="Free monthly quiz, made by pilots for pilots"
            hashtags="A320,quiz,pilot,airline"
            class="btn my-1"
            role="button"
        ><i class="fab fa-whatsapp"></i>
            Whatsapp
        </ShareNetwork>

        <ShareNetwork
            network="twitter"
            url="https://a320expert.com"
            title="Pilot friends, can you do better than me at the A320 Expert quiz ?"
            description="Free monthly quiz, made by pilots for pilots"
            hashtags="A320,quiz,pilot,airline"
            class="btn my-1"
            role="button"
        ><i class="fab fa-twitter"></i>
            Twitter
        </ShareNetwork>

        <ShareNetwork
            network="linkedin"
            url="https://a320expert.com"
            title="Pilot friends, can you do better than me at the A320 Expert quiz ?"
            description="Free monthly quiz, made by pilots for pilots"
            hashtags="A320,quiz,pilot,airline"
            class="btn my-1"
            role="button"
        ><i class="fab fa-linkedin"></i>
            Linkedin
        </ShareNetwork>

    </div>
</template>

<script>

export default {
    name: "SocialMediaShare"
}
</script>

<style scoped>
.share-network-facebook {
    color: white;
    background-color: #1877f2;
    border-color: #1877f2;
}
.share-network-twitter {
    color: white;
    background-color: #1da1f2;
    border-color: #1da1f2;
}
.share-network-linkedin {
    color: white;
    background-color: #0a66c2;
    border-color: #0a66c2;
}
.share-network-whatsapp {
    color: white;
    background-color: #25d366;
    border-color: #25d366;
}
</style>