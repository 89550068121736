<template>
    <div id="app">
        <!-- ========== HEADER ========== -->
        <header
            id="header"
            class="header"
            data-hs-header-options='{
              "fixMoment": 1000,
              "fixEffect": "slide"
            }'
        >
            <div class="header-section">
                <div id="logoAndNav" class="container">
                    <!-- ========== HEADER ========== -->
                    <b-nav
                        class="js-mega-menu navbar navbar-light bg-white navbar-expand-lg"
                    >
                        <div class="container">
                            <b-navbar-brand>
                                <router-link
                                    class="navbar-brand"
                                    :to="{ name: 'home' }"
                                    ><img src="@/assets/logo.jpg" alt="Logo"
                                /></router-link>
                            </b-navbar-brand>
                            <b-navbar-toggle
                                class="navbar-toggler btn btn-icon btn-sm rounded-circle"
                                target="nav-collapse"
                            ></b-navbar-toggle>
                        </div>

                        <b-collapse id="nav-collapse" is-nav>
                            <div class="navbar-body header-abs-top-inner">
                                <ul class="js-scroll-nav navbar-nav">
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'home' }"
                                            >Quiz</router-link
                                        >
                                    </li>
                                    <!-- <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            :href="SURVEY_URL"
                                            target="_blank"
                                        >Survey</a>
                                    </li> -->
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'privacy' }"
                                            >Privacy</router-link
                                        >
                                    </li>
                                </ul>
                            </div>
                        </b-collapse>
                    </b-nav>
                </div>
            </div>
        </header>
        <!-- ========== END HEADER ========== -->

        <router-view></router-view>

        <!-- ========== FOOTER ========== -->
        <footer class="bg-dark">
            <div class="container">
                <div class="space-1">
                    <div class="row align-items-md-center mb-1">
                        <div class="col-md-6 mb-4 mb-md-0">
                            <!-- Nav Link -->
                            <ul
                                class="nav nav-sm nav-white nav-x-sm align-items-center"
                            >
                                <router-link :to="{ name: 'home' }">
                                    <img
                                        class="brand"
                                        src="@/assets/logo-white.png"
                                        alt="Logo"
                                    />
                                </router-link>
                            </ul>
                            <!-- End Nav Link -->
                        </div>
                    </div>
                    <div class="row align-items-md-center mb-7">
                        <div class="col-md-6 mb-4 mb-md-0">
                            <!-- Nav Link -->
                            <ul
                                class="nav nav-sm nav-white nav-x-sm align-items-center"
                            >
                                <li class="nav-item">
                                    <router-link
                                        :to="{ name: 'privacy' }"
                                        class="nav-link"
                                        >Privacy</router-link
                                    >
                                </li>
                            </ul>
                            <!-- End Nav Link -->
                        </div>

                        <div class="col-md-6 text-md-right">
                            <ul class="list-inline mb-0">
                                <!-- Social Networks -->
                                <li class="list-inline-item">
                                    <a
                                        class="btn btn-xs btn-icon btn-soft-light"
                                        :href="`${FACEBOOK_GROUP}`"
                                    >
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a
                                        class="btn btn-xs btn-icon btn-soft-light"
                                        :href="`${LINKEDIN_GROUP}`"
                                    >
                                        <i class="fab fa-linkedin"></i>
                                    </a>
                                </li>
                                <!-- End Social Networks -->
                            </ul>
                        </div>
                    </div>

                    <!-- Copyright -->
                    <div class="w-md-75 text-lg-center mx-lg-auto">
                        <p class="text-white opacity-sm small">
                            &copy; A320 Expert 2021. All rights reserved.
                        </p>
                    </div>
                    <!-- End Copyright -->
                </div>
            </div>
        </footer>
        <!-- ========== END FOOTER ========== -->
    </div>
</template>

<script>
import { FACEBOOK_GROUP, LINKEDIN_GROUP, SURVEY_URL } from "./urls.js";

export default {
    name: "App",
    data() {
        return {
            FACEBOOK_GROUP: FACEBOOK_GROUP,
            LINKEDIN_GROUP: LINKEDIN_GROUP,
            SURVEY_URL: SURVEY_URL,
        };
    },
};
</script>

<style>
#app {
    background: #eff2f7;
}

.navbar-brand img {
    min-width: 200px;
    margin-bottom: 15px;
    margin-top: 15px;
}
</style>
