<template>
    <!-- ========== CONTAINER ========== -->
    <main class="container">
        <!-- Subscribe to Newsletter Section -->
        <div class="container space-1 space-lg-2">
            <subscribe></subscribe>
        </div>
        <!-- End Subscribe Section -->

        <!-- Quiz Section -->
        <div class="starter-template text-center">
            <Quiz />
        </div>
        <!-- End of Quiz Section -->
        <!-- Social media Section -->
        <div class="container text-center space-2 space-lg-2 ">
            <div class="mb-5">
                <span class="d-block small font-weight-bold text-cap mb-2">Share the word</span>
                <h2>Share to challenge your friends</h2>
            </div>
            <SocialMediaShare />
        </div>
        <!-- End Social media Section -->
    </main>
    <!-- ========== END CONTAINER ========== -->
</template>

<script>
import Quiz from '../components/Quiz.vue'
import Subscribe from '../components/Subscribe.vue'
import SocialMediaShare from '../components/SocialMediaShare.vue'

import { posthog } from '../posthog.js'

export default {
    name: 'Privacy',
    components: {
        Quiz, Subscribe, SocialMediaShare
    },
    mounted () {
        posthog.capture('$pageview');
    }
}
</script>