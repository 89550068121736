<template>
    <div class="quiz ">
        <div class="mb-5">
            <span class="d-block small font-weight-bold text-cap mb-2"
                >Our A320 Monthly Quiz</span
            >
            <h2>{{ title }}</h2>
            <p>
                Practice with an expertise you can thrust
                <i
                    class="em em-wink mx-2"
                    aria-role="presentation"
                    aria-label="BIRD"
                ></i
                >&nbsp;&nbsp;
                <i
                    class="em em-airplane"
                    aria-role="presentation"
                    aria-label="BIRD"
                ></i>
            </p>
            <!-- <div
                class="alert alert-soft-primary"
                role="alert"
            >
                Do you like our quizzes? Take 5 minutes to help us by answering a few questions
                <b-button
                    class="mx-3"
                    variant="success"
                    :href="SURVEY_URL"
                    target="_blank"
                    pill
                >Take the survey</b-button>
            </div> -->
        </div>

        <div id="quiz-content">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-6 mb-3">
                    <div class="d-flex flex-wrap">
                        <a
                            v-for="(question,
                            questionIndex) in sitting.questions"
                            :key="questionIndex"
                            :href="`#${questionIndex}`"
                            :class="
                                getNavQuestionStyle(
                                    sitting.questions[questionIndex].isCorrect
                                )
                            "
                            >{{ questionIndex + 1 }}</a
                        >
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 text-right mb-3">
                    <a
                        v-if="isDone"
                        type="button"
                        class="btn btn-primary"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Answer all the questions to finish the test"
                        :disabled="!isDone"
                        href="#results"
                        >See results</a
                    >
                    <button
                        v-else
                        type="button"
                        class="btn btn-secondary"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Answer all the questions to finish the test"
                        disabled
                    >
                        See results
                    </button>
                </div>
            </div>
            <div id="questions" class="text-left">
                <div
                    class="row"
                    v-for="(question, questionIndex) in sitting.questions"
                    :key="questionIndex"
                    :id="questionIndex"
                >
                    <div class="col-12">
                        <div class="card mb-6 card-bordered">
                            <div class="card-body">
                                <p class="lead" v-html="question.content">
                                    {{ question.content }}
                                </p>
                                <p
                                    v-if="question.allow_multiple_answers"
                                    class="text-muted"
                                >
                                    Select all that apply
                                </p>
                                <form action="" :id="questionIndex">
                                    <div class="card-text">
                                        <ul class="list-group mb-4">
                                            <li
                                                v-for="(answer,
                                                answerIndex) in question.answers"
                                                :key="answerIndex"
                                                class="list-group-item"
                                                @click.self="
                                                    selectAnswer(
                                                        questionIndex,
                                                        answerIndex
                                                    )
                                                "
                                            >
                                                <label
                                                    v-if="
                                                        !question.allow_multiple_answers
                                                    "
                                                    :for="
                                                        `Q${questionIndex}A${answerIndex}`
                                                    "
                                                >
                                                    <input
                                                        type="radio"
                                                        name="answer"
                                                        :value="answerIndex"
                                                        :id="
                                                            `Q${questionIndex}A${answerIndex}`
                                                        "
                                                        v-model="
                                                            sitting.questions[
                                                                questionIndex
                                                            ].selectedAnswer
                                                        "
                                                        :disabled="
                                                            question.checked
                                                        "
                                                    />
                                                    {{ answer.content }}</label
                                                >
                                                <label
                                                    v-else
                                                    :for="
                                                        `Q${questionIndex}A${answerIndex}`
                                                    "
                                                    class="ml-2"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        :value="answerIndex"
                                                        :id="
                                                            `Q${questionIndex}A${answerIndex}`
                                                        "
                                                        v-model="
                                                            sitting.questions[
                                                                questionIndex
                                                            ].selectedAnswer
                                                        "
                                                        :disabled="
                                                            question.checked
                                                        "
                                                    />

                                                    {{ answer.content }}
                                                </label>

                                                <span
                                                    v-if="
                                                        answer.correct &&
                                                            question.checked &&
                                                            !question.isCorrect
                                                    "
                                                    class="text-danger ml-5"
                                                >
                                                    <strong
                                                        >This was the correct
                                                        answer</strong
                                                    >
                                                </span>
                                            </li>
                                        </ul>
                                        <b-button
                                            v-if="!question.checked"
                                            @click="checkAnswer(questionIndex)"
                                            variant="outline-info"
                                            class="btn-check btn-large btn-block "
                                            :disabled="
                                                question.selectedAnswer === null
                                            "
                                            >Check</b-button
                                        >
                                        <div v-else>
                                            <div
                                                v-if="question.isCorrect"
                                                class="alert alert-soft-success"
                                                role="alert"
                                            >
                                                This is the right answer !
                                            </div>
                                            <div
                                                v-else
                                                class="alert alert-soft-danger"
                                                role="alert"
                                            >
                                                Wrong answer !
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="card-text" v-if="question.checked">
                                    <h5>Explanation</h5>
                                    <div v-html="question.explanation">
                                        {{ question.explanation }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showResults" class="row text-left" id="results">
            <div class="col">
                <div class="card mb-4 card-bordered">
                    <div class="card-body text-center ">
                        <!-- <div class="alert alert-soft-primary" role="alert">
                            Did you like this ? Take 5 minutes to help us by
                            answering a few questions
                            <b-button
                                variant="success"
                                class="mx-3"
                                :href="SURVEY_URL"
                                target="_blank"
                                pill
                                >Take the survey</b-button
                            >
                        </div> -->
                        <div class="mt-5 mb-3">
                            <h2>Your score</h2>
                        </div>
                        <div class="font-size-5 mb-5">
                            <span :class="scoreClass"
                                >{{ percentScore }} %</span
                            >
                        </div>
                        <div class="w-md-90 w-lg-70 mx-md-auto mb-6">
                            <div class="mb-3">
                                <h3>
                                    Register and receive new A320 quizzes every
                                    month
                                </h3>
                            </div>
                            <subscribe-form
                                formId="subscribe2"
                            ></subscribe-form>
                            <p class="small">
                                You can unsubscribe at any time using the link
                                in our newsletter
                            </p>
                        </div>
                        <b-button
                            id="gobacktoquiz"
                            href="#quiz-content"
                            variant="outline-info"
                            class="btn-large btn-block "
                            >Go back to the quiz</b-button
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import quiz from "../data/2021_08.json";
import SubscribeForm from "./SubscribeForm.vue";
import { SURVEY_URL } from "../urls.js";

import { posthog } from "../posthog.js";

export default {
    name: "Quiz",
    components: { "subscribe-form": SubscribeForm },

    data() {
        const sitting = this.initSitting();

        return {
            title: quiz.title,
            description: quiz.description,
            sitting: sitting,
            showResults: false,
            SURVEY_URL: SURVEY_URL,
        };
    },
    methods: {
        /**
         * Initialize the sitting by resetting the score and selected answers
         */
        initSitting() {
            let sitting = {
                questions: [],
                score: 0,
                nbAnswer: 0,
                nbQuestion: 0,
            };
            quiz.questions.forEach((item) => {
                const question = JSON.parse(JSON.stringify(item));
                if (question.allow_multiple_answers) {
                    sitting.questions.push({
                        ...question,
                        selectedAnswer: [],
                        checked: false,
                        isCorrect: null,
                    });
                } else {
                    sitting.questions.push({
                        ...question,
                        selectedAnswer: null,
                        checked: false,
                        isCorrect: null,
                    });
                }
                sitting.nbQuestion += 1;
            });
            return sitting;
        },
        /**
         * Reset progress and start a new quiz
         */
        resetSitting() {
            this.showResults = false;
            this.sitting = this.initSitting();
        },
        /**
         * Select an answer by clicking anywhere on the answer block
         */
        selectAnswer(questionIndex, answerIndex) {
            // if the question has not been checked yet, then mark the answer as selected
            if (!this.sitting.questions[questionIndex].checked) {
                if (
                    this.sitting.questions[questionIndex].allow_multiple_answers
                ) {
                    // if the answer is already selected, remove it
                    const index = this.sitting.questions[
                        questionIndex
                    ].selectedAnswer.indexOf(answerIndex);
                    if (index > -1) {
                        this.sitting.questions[
                            questionIndex
                        ].selectedAnswer.splice(index, 1);
                    } else {
                        // otherwise add it to the list
                        this.sitting.questions[
                            questionIndex
                        ].selectedAnswer.push(answerIndex);
                    }
                    console.debug(
                        "After checkbox click: ",
                        this.sitting.questions[questionIndex].selectedAnswer
                    );
                } else {
                    this.sitting.questions[
                        questionIndex
                    ].selectedAnswer = answerIndex;
                    console.debug(
                        "After radio click: ",
                        this.sitting.questions[questionIndex].selectedAnswer
                    );
                }
            }

            event.preventDefault();
        },
        /**
         * Check if the select answer is correct after the user clicks on "Check"
         */
        checkAnswer(questionIndex) {
            const question = this.sitting.questions[questionIndex];
            const answers = this.sitting.questions[questionIndex].answers;
            const selectedAnswer = this.sitting.questions[questionIndex]
                .selectedAnswer;
            console.log(
                `Selected answer: ${selectedAnswer} ${typeof selectedAnswer}`
            );
            this.sitting.questions[questionIndex].checked = true;
            let selectedAnswers;
            if (question.type === "MCQuestion") {
                if (!question.allow_multiple_answers) {
                    selectedAnswers = [selectedAnswer];
                } else {
                    selectedAnswers = selectedAnswer;
                }

                let isCorrect = true;
                for (let i = 0; i < answers.length; i++) {
                    if (answers[i].correct && selectedAnswers.indexOf(i) < 0) {
                        isCorrect = false;
                        break;
                    }
                }
                if (isCorrect) {
                    this.sitting.questions[questionIndex].isCorrect = true;
                    this.sitting.score += 1;
                } else {
                    this.sitting.questions[questionIndex].isCorrect = false;
                }
            }

            this.sitting.nbAnswer += 1;

            // record custom Posthog actions
            if (this.sitting.nbAnswer == 1) {
                posthog.capture("answered_1");
            } else if (this.sitting.nbAnswer == 5) {
                posthog.capture("answered_5");
            }
            if (this.isDone) {
                this.showResults = true;
                posthog.capture("answered_all", { score: this.percentScore });
            }
        },
        /**
         * Change the style of a navigation link based on the user answer
         * Show green if the answer was correct, red otherwise
         */
        getNavQuestionStyle(isCorrect) {
            switch (isCorrect) {
                case null:
                    return {
                        "nav-question": true,
                    };
                case true:
                    return {
                        "nav-question": true,
                        "nav-question__success": true,
                    };
                case false:
                    return {
                        "nav-question": true,
                        "nav-question__failure": true,
                    };
            }
        },
    },
    computed: {
        isDone() {
            return (
                this.sitting.nbAnswer > 0 &&
                this.sitting.nbAnswer === this.sitting.nbQuestion
            );
        },
        percentScore() {
            if (this.sitting.nbQuestion > 0) {
                return (this.sitting.score / this.sitting.nbQuestion) * 100;
            } else {
                return 100;
            }
        },
        scoreClass() {
            if (this.percentScore >= 90) {
                return { "text-success": true };
            } else if (this.percentScore >= 75) {
                return { "text-warning": true };
            } else {
                return { "text-danger": true };
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*********************************/
/* Navigation question id         */
/*********************************/

.nav-question {
    position: relative;
    display: block;
    padding: 0.25rem 0.25rem;
    background-color: #fff;
    border: 0.0625rem solid #e7eaf3;
    width: 40px;
    text-align: center;
}

.nav-question__success {
    background-color: #00c9a7;
    color: white;
}

.nav-question__failure {
    background-color: #ed4c78;
    color: white;
}

.nav-question__current {
    border: 3px solid #377dff;
}

.nav-question__answered {
    background-color: #8c98a4;
    color: white;
}
</style>
