<template>
    <!-- Form -->
    <form
        ref="form"
        class="js-validate mb-2"
        :id="formId"
        data-type="subscription"
        method="POST"
        action="https://2e25aaa2.sibforms.com/serve/MUIEAGSR5jvuc0drc_FUVESCTSBuZSX3swhXaxo_5Z5XkNWWaMSUcDfwqM3oHb-UB2z617vDwXXXMdxdrOq-evPjJR-ChO6hb7RwYGP2HMwPzF7rs8YMUCIOGP8tSlsb4Thxgku4BIrnMDQwz9VrKbgtY9RnFOVa4Zci0W9l3a8NJTClhYIOoHyTRBKm_oxdOOTZoTtfptfhcYKR"
    >
        <div v-if="isLoading">
            <b-spinner
                variant="primary"
                label="Submitting your request..."
            ></b-spinner>
        </div>
        <div v-else>
            <b-alert
                v-model="showConfirmation"
                variant="soft-success"
                dismissible
            >You are now suscribed to our newsletter!
            </b-alert>
            <label
                class="sr-only"
                for="signupSrEmail"
            >Email</label>
            <div class="input-group">
                <input
                    type="email"
                    class="form-control"
                    name="EMAIL"
                    id="EMAIL"
                    :form="formId"
                    placeholder="yourname@gmail.com"
                    aria-label="yourname@gmail.com"
                    required
                >
                <div class="input-group-append">
                    <button
                        type="submit"
                        :form="formId"
                        class="btn btn-block btn-primary"
                        @submit="subscribe"
                    >Subscribe</button>
                </div>
            </div>

            <input
                type="hidden"
                name="email_address_check"
                value=""
            >
            <input
                type="hidden"
                name="locale"
                value="en"
            >
            <input
                type="hidden"
                name="html_type"
                value="simple"
            >
        </div>
    </form>
    <!-- End Form -->
</template>
<script>

function sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
    name: 'SubscribeForm',
    props: {
        formId: {
            type: String,
            required: false,
            default: "sib-form"

        }
    },
    data () {
        return {
            isLoading: false,
            showConfirmation: false,
            url: "https://2e25aaa2.sibforms.com/serve/MUIEAGSR5jvuc0drc_FUVESCTSBuZSX3swhXaxo_5Z5XkNWWaMSUcDfwqM3oHb-UB2z617vDwXXXMdxdrOq-evPjJR-ChO6hb7RwYGP2HMwPzF7rs8YMUCIOGP8tSlsb4Thxgku4BIrnMDQwz9VrKbgtY9RnFOVa4Zci0W9l3a8NJTClhYIOoHyTRBKm_oxdOOTZoTtfptfhcYKR",
            form: {
                email: "",
                locale: "en",
                html_type: "simple",
                email_address_check: ""

            },
            email: ""
        }
    },
    methods: {
        subscribe (e) {
            this.isLoading = true
            console.log("User ", this.form.email, " subscribed to newsletter")
            sleep(1000).then(() => { console.log("World!"); });
            console.log(e);
        }
    }
}
</script>